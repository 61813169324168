(function(){
var sz = document.createElement('script'); sz.type = 'text/javascript'; sz.async = true;
sz.src = '//siteimproveanalytics.com/js/siteanalyze_6233732.js';
var sci = document.getElementsByTagName('script')[0]; sci.parentNode.insertBefore(sz, sci);
console.log('test');


/* TESTING CRB
var nb = document.createElement('script'); nb.type = 'text/javascript'; nb.async = true;
nb.src = '//cdn.noibu.com/collect.js';
var sci = document.getElementsByTagName('script')[0]; sci.parentNode.insertBefore(nb, sci);
*/
})();